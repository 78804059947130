/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOriginalUrl = /* GraphQL */ `
  query GetOriginalUrl($companyId: Int!) {
    getOriginalUrl(companyId: $companyId) {
      companyId
      originalUrl
    }
  }
`
export const getLoginIpAddressList = /* GraphQL */ `
  query GetLoginIpAddressList($companyId: Int!) {
    getLoginIpAddressList(companyId: $companyId) {
      companyId
      ipAddress
    }
  }
`
export const getCompanyNotice = /* GraphQL */ `
  query GetCompanyNotice($filter: TableCompanyNoticeFilterInput) {
    getCompanyNotice(filter: $filter) {
      companyNotice {
        body
        created_at
        created_by
        deleted_at
        deleted_by
        id
        status
        updated_at
        updated_by
        url
      }
      createdUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
      updatedUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
      deletedUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getCompanyNoticeList = /* GraphQL */ `
  query GetCompanyNoticeList(
    $filter: TableCompanyNoticeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyNoticeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        companyNotice {
          body
          created_at
          created_by
          deleted_at
          deleted_by
          id
          status
          updated_at
          updated_by
          url
        }
        createdUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        updatedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        deletedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCustomerNotice = /* GraphQL */ `
  query GetCustomerNotice($filter: TableCustomerNoticeFilterInput) {
    getCustomerNotice(filter: $filter) {
      customerNotice {
        body
        created_at
        created_by
        deleted_at
        deleted_by
        id
        status
        updated_at
        updated_by
        url
      }
      createdUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
      updatedUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
      deletedUser {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getCustomerNoticeList = /* GraphQL */ `
  query GetCustomerNoticeList(
    $filter: TableCustomerNoticeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerNoticeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        customerNotice {
          body
          created_at
          created_by
          deleted_at
          deleted_by
          id
          status
          updated_at
          updated_by
          url
        }
        createdUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        updatedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        deletedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getAdminUser = /* GraphQL */ `
  query GetAdminUser($filter: TableAdminUserFilterInput) {
    getAdminUser(filter: $filter) {
      id
      status
      username
      password
      name
      created_at
      updated_at
      deleted_at
    }
  }
`
export const getAdminUserList = /* GraphQL */ `
  query GetAdminUserList(
    $filter: TableAdminUserFilterInput
    $limit: Int
    $offset: Int
  ) {
    getAdminUserList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        id
        status
        username
        password
        name
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyList = /* GraphQL */ `
  query GetCompanyList(
    $filter: TableCompanyFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        company {
          id
          status
          name
          name_kana
          stock_signage
          zip_code
          prefecture_id
          address
          tel
          fax
          mail
          url
          business_hours
          regular_holiday
          emergency_name
          emergency_tel
          emergency_time
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
          business_hours_text
          regular_holiday_text
          emergency_time_text
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getCompanyAndDashboardList = /* GraphQL */ `
  query GetCompanyAndDashboardList(
    $filter: TableCompanyFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyAndDashboardList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        company {
          id
          status
          name
          name_kana
          stock_signage
          zip_code
          prefecture_id
          address
          tel
          fax
          mail
          url
          business_hours
          regular_holiday
          emergency_name
          emergency_tel
          emergency_time
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
          business_hours_text
          regular_holiday_text
          emergency_time_text
        }
        dashboard {
          pk
          sk
          totalBuildings
          totalRooms
          totalAvailableRooms
          totalContracts
          totalAppUsers
          dailyAppUsers
          messageOpen
          messageInProgress
          messagePending
          messageComplete
          applicationResidentCancellationOpen
          applicationResidentCancellationInProgress
          applicationResidentCancellationPending
          applicationResidentInfoChangeOpen
          applicationRoomCheckOpen
          applicationBicycleParkingStickerOpen
          applicationCertificateOfConsentToUseParkingSpaceOpen
          applicationPetOpen
          appUserMonthly
          appUserWeekly
          appUserDaily
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getDataLinkHistoryList = /* GraphQL */ `
  query GetDataLinkHistoryList(
    $filter: TableDataLinkHistoryFilterInput
    $offset: Int
    $limit: Int
  ) {
    getDataLinkHistoryList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        data_link_history {
          id
          status
          company_id
          data_link_type_id
          data_link_state_id
          data_type_id
          attachment_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        error_attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getPrefectureList = /* GraphQL */ `
  query GetPrefectureList {
    getPrefectureList {
      data {
        created_at
        deleted_at
        display_order
        id
        name
        name_kana
        prf_cd
        status
        updated_at
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getPackageSize = /* GraphQL */ `
  query GetPackageSize($companyId: Int!) {
    getPackageSize(companyId: $companyId) {
      available_slot_size
      top_page_display_size
      remark
    }
  }
`
export const getFaqList = /* GraphQL */ `
  query GetFaqList(
    $filter: TableFaqFilterInput
    $companyId: Int
    $offset: Int
    $limit: Int
  ) {
    getFaqList(
      filter: $filter
      companyId: $companyId
      offset: $offset
      limit: $limit
    ) {
      data {
        faq {
          id
          status
          company_id
          category_id
          question
          answer
          display_order
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
export const getMessageTemplateList = /* GraphQL */ `
  query GetMessageTemplateList(
    $filter: TableMessageTemplateFilterInput
    $companyId: Int
    $offset: Int
    $limit: Int
  ) {
    getMessageTemplateList(
      filter: $filter
      companyId: $companyId
      offset: $offset
      limit: $limit
    ) {
      data {
        messageTemplate {
          id
          status
          company_id
          category_id
          title
          body
          display_order
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        page
        rowsPerPage
      }
    }
  }
`
